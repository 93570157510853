import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import ViewPricingSection from "../components/ViewPricingSection";
import SimpleHeader from "../components/SimpleHeader";
import CenteredBlurbsSection from "../components/CenteredBlurbsSection";
import HashtagHidden from "../components/HashtagHidden";


// eslint-disable-next-line
export const EquityPageTemplate = ({ intro, pricing, compPhil, closing }) => {
  return (
    <>
      <section className="section is-small no-padding-bottom">
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Global Market Compensation Data - Kamsa</title>
            <meta property="og:title" content="Equity Model And Budget - Kamsa" />
            <meta name="description" content="Kamsa is a compensation management system that combines global salary data & expert support from compensation consultants for simplified compensation planning." />
          </Helmet>
        </div>
      </section>
      {/* INTRO START */}
      <section className="section section--gradient is-small">
          <div className="container">
            <div className="columns">
              <SimpleHeader
                heading={intro.heading}
                header={intro.header}
                subheader={intro.description}
                buttonLink={intro.buttonLink}
                buttonText={intro.buttonText}
                columnSize={'is-7'}
                isPageHeader
              />
            </div>
          </div>
      </section>
      {/* INTRO FINISH */}
      {/* COMPENSATION PHILOSOPHY START */}
      <section className="section section--gradient is-small no-padding-top">
          <div className="container">
              <div className="columns is-vcentered">
                  <div className="column">
                      <div className="block">
                          <img src={compPhil.image.publicURL} alt={compPhil.image.title} />
                      </div>
                  </div>
                  <div className="column">
                      <div className="block">
                          <h2 className="title is-2 has-text-weight-bold">{compPhil.title}</h2>
                      </div>
                      <div className="block">
                          <h4 className="subtitle">{compPhil.subtitle}</h4>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      {/* COMPENSATION PHILOSOPHY END */}
      {/* CLOSING START */}
      <section className="section section--gradient is-small">
        <div className="columns is-vcentered">
          <div className="column is-pulled-left">
              <CenteredBlurbsSection
                heading={closing.heading}
                subheading={closing.subheading}
                content={closing.content}
                useImage={false}
                noIcon
            />
          </div>
          <div className="column is-pulled-right">
            <div className="block">
                <img src={'/img/equityPageReliableHero.svg'} alt={'equity hero'} />
            </div>
          </div>
        </div>
      </section>
      {/* CLOSING END */}
      <div className="section is-medium has-text-centered no-padding-top">
        <iframe style={{ maxWidth: '100%' }} width="1000" height="600"
                src="https://www.youtube.com/embed/NtnBOzynQOs?si=qEeCpdAze_RcjqqR" title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
      </div>
      {/* PRICING START */}
      <section className="section section--gray is-medium">
          <ViewPricingSection
              title={pricing.title}
              subtitle={pricing.subtitle}
              buttonLink={pricing.buttonLink}
              buttonText={pricing.buttonText}
          />
      </section>
      {/* PRICING END */}
    </>
  );
};

EquityPageTemplate.propTypes = {
  intro: PropTypes.shape({
    heading: PropTypes.string,
    header: PropTypes.string,
    description: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  compPhil: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  closing: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.array,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string
  }),
  pricing: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
  }),
};

const EquityPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <EquityPageTemplate
          data={frontmatter}
          intro={frontmatter.emabPageIntro}
          pricing={frontmatter.emabPagePricing}
          compPhil={frontmatter.emabPageCompPhil}
          closing={frontmatter.emabPageClosing}
      />
      <HashtagHidden hashtags={frontmatter.emabPageHashtags} />
    </Layout>
  );
};

EquityPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EquityPage;

export const EquityPageQuery = graphql`
  query EquityModelAndBudgetPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        emabPageIntro {
          heading
          header
          description
        }
        emabPageCompPhil {
          image {
            publicURL
          }
          title
          subtitle
        }
        emabPageClosing {
          heading
          subheading
          content {
            image {
                publicURL
            }
            text
          }
          buttonLink
          buttonText
        }
        emabPagePricing {
          title
          subtitle
          buttonLink
          buttonText
        }
        emabPageHashtags {
          title
        }
      }
    }
  }
`;
